/* ========================================================================
     Component: offsidePdfPanel
 ========================================================================== */

//
// A sidebar in offcanvas mode
// Open status toggled via JS
// -----------------------------------------

$offsidePdfPanel-bg:           #fff;
$offsidePdfPanel-border-color: grayscale(shade-color($offsidePdfPanel-bg, 20%));
$offsidePdfPanel-color:        $body-color;
$offsidePdfPanel-wd:            500px ;// $aside-wd + 200px;      // match the width with the main aside component

.offsidePdfPanel {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: $offsidePdfPanel-wd;
    margin-top: $navbar-withmenu-height;
    border-left: 1px solid $offsidePdfPanel-border-color;
    background-color: $offsidePdfPanel-bg;
    color: $offsidePdfPanel-color;
    overflow: hidden !important;
    -webkit-overflow-scrolling: touch;
    z-index: -1;

    >.list-group {
        min-height: 100%;
        overflow: hidden;
        -webkit-transform: translateZ(0px);
    }

    .card.pdf-sidebar > div > .card {
        height: 100%;
    }
    .progress {
        border: 0;
    }

    .tab-content {
        padding: 0;
        border: 0;
    } // change style of tab component inside offsidebar
    .nav-tabs {
        border-bottom: 1px solid rgba(0, 0, 0, .05);
        >.nav-item>.nav-link {
            background-color: transparent;
            border: 0;
            border-right: 1px solid rgba(0, 0, 0, .05);
            border-radius: 0;
            color: $text-muted;
            &.active {
                color: $primary;
            }
        }
    }


    @include media-breakpoint-up(md) {
        margin-top: $navbar-withmenu-height;
    }
}

// Global open status (attach to body)
.offsidePdfPanel-open {
    .offsidePdfPanel {
        overflow-y: auto;
        z-index: $z-index-main-section + 5;
    }
    @include media-breakpoint-up(md) {
        overflow-y: auto;
    }
}

.offsidePdfPanel-left {
    .offsidePdfPanel {
        left: $aside-wd; //200px;
        z-index: 140;
    }
}

.offsidePdfPanel-further-left {
    .offsidePdfPanel {
        left: 70px; 
        z-index: 140;
    }
}

.offsidePdfPanel-w1000 {
    .offsidePdfPanel {
        left: 70px; 
        z-index: 140;
        width: 1000px;
        margin-top: 0px;
    }
}

// No tranformation devices
.offsidePdfPanel {
    right: -$offsidePdfPanel-wd;
}

.no-csstransforms3d {
    .offsidePdfPanel-open {
        .offsidePdfPanel {
            right: 0;
        }
    }
}


/* Transformation ready devices*/

.csstransforms3d {
    .offsidePdfPanel {
        right: 0;
        transform: translate3d( ($offsidePdfPanel-wd), 0, 0);
        transition: transform .3s ease, z-index 0s linear .3s;
    }
    .offsidePdfPanel-open {
        .offsidePdfPanel {
            transform: translate3d(0, 0, 0);
            transition: transform .3s ease;
        }
    }
}
