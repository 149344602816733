
// Cutoms Gray colors for theme
$gray-darker:           #232735;
$gray-dark:             #3a3f51;
$gray:                  #dde6e9;
$gray-light:            #e4eaec;
$gray-lighter:          #edf1f2;


// Custom brand colors for theme
$inverse:               #131e26;
$purple:                #7266ba;
$pink:                  #f532e5;
$yellow:                #fad732;
$green:                 #37bc9b;
$navy:                  #007DBF;

$primary:               #5d9cec;
$secondary:             #6c757d; //   #fff;
$success:               #27c24c;
$info:                  #23b7e5;
$warning:               #ff902b;
$danger:                #f05050;
$dark:                  #3a3f51;

$light:         $gray-light !default;    // this changes the bootstrap $light to match our themese gray-light

// Custom color variants
$amountOfLight: 10%;

$primary-light:         tint-color($primary, $amountOfLight);
$success-light:         tint-color($success, $amountOfLight);
$info-light:            tint-color($info, $amountOfLight);
$warning-light:         tint-color($warning, $amountOfLight);
$danger-light:          tint-color($danger, $amountOfLight);
$inverse-light:         tint-color($inverse, $amountOfLight);
$green-light:           tint-color($green, $amountOfLight);
$pink-light:            tint-color($pink, $amountOfLight);
$purple-light:          tint-color($purple, $amountOfLight);
$yellow-light:          tint-color($yellow, $amountOfLight);
$primary-dark:          shade-color($primary, $amountOfLight);
$success-dark:          shade-color($success, $amountOfLight);
$info-dark:             shade-color($info, $amountOfLight);
$warning-dark:          shade-color($warning, $amountOfLight);
$danger-dark:           shade-color($danger, $amountOfLight);
$inverse-dark:          shade-color($inverse, $amountOfLight);
$green-dark:            shade-color($green, $amountOfLight);
$pink-dark:             shade-color($pink, $amountOfLight);
$purple-dark:           shade-color($purple, $amountOfLight);
$yellow-dark:           shade-color($yellow, $amountOfLight);

// Theme variables

// used for sidebars and content spacing
$navbar-height:                    55px;
$topnav-height:                  44px; // used to set top for elements under new topmenu
$navbar-withmenu-height:                 $navbar-height + $topnav-height + 2px;

// Button secondary redefinition style for theme
$bg-secondary-color:              #333;
$bg-secondary-bg:                 #fff;
$bg-secondary-border:             #eaeaea;

// Top Border color for custom default cards (.card-default)
$card-default-border-color:        #cfdbe2;


// ---------------------
// Utilities variables
// ---------------------

$text-alpha:            rgba(255,255,255,.5);
$text-alpha-inverse:    rgba(0,0,0,.5);

$font-size-base:        1rem;
$text-sm:               $font-size-base * 0.74375;
$text-md:               $font-size-base * 1.6625;
$text-lg:               $font-size-base * 2.625;

$border-color:          rgba(0,0,0,.12);

$wd-xxs:                60px;
$wd-xs:                 90px;
$wd-sm:                 150px;
$wd-sd:                 200px; // sd == standard
$wd-md:                 240px;
$wd-lg:                 280px;
$wd-xl:                 320px;
$wd-xxl:                360px;
$wd-wide:               100%;
$wd-auto:               auto;
$wd-zero:               0;


// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

// Color system

$colors: (
    "purple":     $purple,
    "pink":       $pink,
    "yellow":     $yellow,
    "green":      $green,
    "primary":    $primary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    'inverse':    $inverse,

    'primary-light': $primary-light,
    'primary-dark':  $primary-dark,
    'success-light': $success-light,
    'success-dark':  $success-dark,
    'info-light':    $info-light,
    'info-dark':     $info-dark,
    'warning-light': $warning-light,
    'warning-dark':  $warning-dark,
    'danger-light':  $danger-light,
    'danger-dark':   $danger-dark,
    'green-light':   $green-light,
    'green-dark':    $green-dark,
    'pink-light':    $pink-light,
    'pink-dark':     $pink-dark,
    'purple-light':  $purple-light,
    'purple-dark':   $purple-dark,
    'inverse-light': $inverse-light,
    'inverse-dark':  $inverse-dark,
    'yellow-light':  $yellow-light,
    'yellow-dark':   $yellow-dark,
);


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "gray":       $light,
  "dark":       $dark,
  "white":      #FAF9F6
);

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
// scss-docs-end theme-colors-rgb

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   1.7;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      #323a46;
$color-contrast-light:     #fff;

// Body

$body-bg:                   #f5f7fa;
$body-color:                #656565;

$border-radius:               .25rem;
$border-radius-lg:            .375rem;

$line-height-base:            1.528571429;

$headings-line-height:        1.1;

$text-muted:                  #909FA7;

// Tables

$table-accent-bg:             #fafbfc;
$table-hover-bg:              #fafbfc;

$table-border-color:          #eee;

// Buttons + Forms

$input-btn-padding-y:         .375rem;
$input-btn-padding-x:         1rem;

$input-btn-padding-y-sm:      .3125rem;
$input-btn-padding-x-sm:      .625rem;

$input-btn-padding-y-lg:      .625rem;
$input-btn-padding-x-lg:      1.25rem;

$input-height:                2.1875rem; // 35px

// Forms

$input-disabled-bg:                     $gray-lighter;
$input-border-color:                    $gray;
$input-focus-border-color:              #66afe9;
$input-placeholder-color:               #b7bac9;

$form-feedback-icon-valid:              none;
$form-feedback-icon-invalid:            none;

$custom-control-indicator-active-bg:    tint-color($primary, 25%);

// Dropdowns

$dropdown-border-color:             #e1e1e1;

// Navs

$nav-tabs-link-active-bg:           #fff;

// Jumbotron

$jumbotron-bg:                      #fcfcfc;

// List group

$list-group-border-color:           #e4eaec;

// Image thumbnails

$thumbnail-padding:                 .125rem;


// Breadcrumbs

$breadcrumb-bg:                     #fafafa;
$breadcrumb-divider-color:          #c1c2c3;
$breadcrumb-active-color:           $body-color;


// custom

// Logo Display
$logo-dark-display:                                 none;
$logo-light-display:                                block;

// Logo Display - Auth Pages
$logo-auth-dark-display:                            block;
$logo-auth-light-display:                           none;

// User authentication Background
$auth-bg:                                         #ffffff;
$auth-bg-alt:                                     #6658dd;
$auth-img-bg:                                     url("/assets/img/auth/bg-auth.jpg");
$auth-img-pattern-bg:                             url("/assets/images/bg-pattern.jpg");
$auth-img-trial-bg:                               url("/assets/img/auth/bg-trial.webp");



// extra for topbar menu

// Topnav Height (Horizontal)
//$topnav-height:                                     44px;

// Topbar Height
$topbar-height:                                     0px;

// Horizontal Layout
$horizontal-layout-width:                           90%;

// Body minimum height
$body-min-height:                                   1550px;

//Topbar Background
$bg-topbar-light:                                 #ffffff;
$bg-topbar-dark:                                  #2a3146;

//Menu item
$menu-item:                                       #6e768e;
$menu-item-hover:                                 #6559cc;
$menu-item-active:                                #6559cc;
$menu-sub-item-active:                            #6559cc;
$menu-item-size:                                    0.9rem;
$menu-sub-item-size:                                0.8.5rem;

// Horizontal Menu Item
$hori-menu-item-color:                           #111; //   #6e7488;

// Secondary font
$font-family-secondary:                             'IBM Plex Sans', sans-serif;

// Font weight
$font-weight-medium:                                500;
$font-weight-semibold:                              600;

// Boxed layout width
$boxed-layout-width:                                1300px;
