/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.8.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */


 
// Bootstrap initializaton for 5.2 from getBootstrap
// https://getbootstrap.com/docs/5.2/customize/sass/

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// Optional variable overrides here
// + $custom-color: #df711b;
// + $custom-theme-colors: (
// +   "custom": $custom-color
// + );

// 2. Include optional variable overrides here
@import "app/variables";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
// Optional Sass map overrides here
//$theme-colors: map-merge($theme-colors, $custom-theme-colors);

// 5. Include remainder of required parts
// @import "bootstrap/scss/maps";
// @import "bootstrap/scss/mixins";
// @import "bootstrap/scss/root";

// 6. Optionally include any other parts as needed
// @import "bootstrap/scss/utilities";
// @import "bootstrap/scss/reboot";
// @import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/containers";
// @import "bootstrap/scss/grid";
// @import "bootstrap/scss/helpers";

// or use all
@import "bootstrap/scss/bootstrap";

// or use our bootstrap_components file which was setup for bootstrap 4.3 times which may not work now
//@import "bootstrap_components";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 8. Add additional custom code here


// Variables

// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";
@import "app/offsidePanel";
@import "app/offsideMessagePanel";
@import "app/offsidePdfPanel";
@import "app/user-block";
@import "app/settings";

// extra navbar for menu
@import "app/top-navbar.scss";
@import "app/horizontal-nav";

// theme g manually set due to import issue
@import "themes/theme-g.scss";

// Common
@import "app/typo";
@import "app/bootstrap-reset";
@import "app/bootstrap-custom";
@import "app/button-extra";
@import "app/placeholder";
@import "app/cards";
@import "app/circles";
@import "app/dropdown-extra";
@import "app/half-float";
@import "app/animate";
@import "app/slim-scroll";
@import "app/inputs";
@import "app/utils";
@import "app/print";
// Elements
@import "app/spinner";
// Charts
@import "app/radial-bar";
@import "app/chart-flot";
@import "app/chart-easypie";
// Form elements
@import "app/form-select2";
@import "app/form-tags-input";
@import "app/file-upload";
//@import "app/summernote";
@import "app/typeahead";
// Tables
@import "app/table-extras";
//@import "app/table-angulargrid";
// Maps
@import "app/gmap";
//@import "app/vector-map";
// Extras
@import "app/timeline";
@import "app/todo";
@import "app/calendar";
@import "app/mailbox";
@import "app/plans";

// more
@import "custom/plugins/icons";

@import "custom/components/social";

@import "custom/pages/authentication";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "cropperjs/dist/cropper.min.css";


@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins-Regular.ttf') format('truetype');
}

// should be in customise


.clickable-link { 
    color: #5d9cec;
    text-decoration: none;
}
.clickable-link:hover {
    cursor: pointer;
    color: #1b72e2;
    text-decoration: underline;
}

.hoverable {
    cursor: pointer;
}

.minwidth {
    min-width: 180px;
}

.content-heading {
    padding-left: 0px !important;
}

.header-title {
    padding-left: 20px !important;
}

.pointer {
    cursor: pointer;
}

// to get ngx-tabs in center
.justify-center .nav-tabs  {
    justify-content: center !important;
}

.table-slim th, .table-slim td {
  padding: 0.5rem 0.25rem;
}

.table-super-slim th, .table-super-slim td {
  padding: 0.5rem 0.05rem;
}

.table-super-slim .form-control {
  padding: 0.375rem 0.5rem;
}

.table-p0 th, .table-p0 td {
  padding: 0rem;
}

.err {
  background-color: #fceeee;
}

.footer-alt {
    left: 0!important;
    text-align: center;
    background-color: transparent;
}

.footer {
    bottom: 0;
    padding: 19px 15px 20px;
    position: absolute;
    right: 0;
    color: #98a6ad;
    left: 240px;
    background-color: #eeeff3;
}

.offcanvas-start {
    //left: 190px;   // 190px;
    left: $aside-wd;
   // z-index: 15000 !important;
    width: 680px;
  }

  .offcanvas-start-furtherLeft {
    //left: 190px;   // 190px;
    left: 70px;
   // z-index: 15000 !important;
    width: 680px;
  }

  .offcanvas-end-wide {
    width: 83vw !important;
  }

  .home-container {
    margin: 0 auto;
    max-width: map-get($container-max-widths, "lg");

    .home-logo {
        width: 240px;
    }

    .home-text {
        text-align: center;
        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }
    .home-text-big {
        font-size: 3.9375rem;
    }
}

.text-ndisNumber {
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
    font-size: 12px;
}



.min-h-250px {
  min-height: 250px;
}



.hoverable {
    cursor: pointer;
    transition: all 0.2s ease;
  }
  .hoverable:hover {
    transition: all 0.2s ease;
  }
  
  .bgi-no-repeat {
    background-repeat: no-repeat;
  }
  
  .bgi-position-y-top {
    background-position-y: top;
  }
  
  .bgi-position-y-bottom {
    background-position-y: bottom;
  }
  
  .bgi-position-y-center {
    background-position-y: center;
  }
  
  .bgi-position-x-start {
    background-position-x: left;
  }
  
  .bgi-position-x-end {
    background-position-x: right;
  }
  
  .bgi-position-x-center {
    background-position-x: center;
  }
  
  .bgi-position-top {
    background-position: 0 top;
  }
  
  .bgi-position-bottom {
    background-position: 0 bottom;
  }
  
  .bgi-position-center {
    background-position: center;
  }
  
  .bgi-size-auto {
    background-size: auto;
  }
  
  .bgi-size-cover {
    background-size: cover;
  }
  
  .bgi-size-contain {
    background-size: contain;
  }
  
  .bgi-attachment-fixed {
    background-attachment: fixed;
  }
  
  .bgi-attachment-scroll {
    background-attachment: scroll;
  }
  
  @media (min-width: 576px) {
    .bgi-size-sm-auto {
      background-size: auto;
    }
  
    .bgi-size-sm-cover {
      background-size: cover;
    }
  
    .bgi-size-sm-contain {
      background-size: contain;
    }
  
    .bgi-attachment-sm-fixed {
      background-attachment: fixed;
    }
  
    .bgi-attachment-sm-scroll {
      background-attachment: scroll;
    }
  }
  @media (min-width: 768px) {
    .bgi-size-md-auto {
      background-size: auto;
    }
  
    .bgi-size-md-cover {
      background-size: cover;
    }
  
    .bgi-size-md-contain {
      background-size: contain;
    }
  
    .bgi-attachment-md-fixed {
      background-attachment: fixed;
    }
  
    .bgi-attachment-md-scroll {
      background-attachment: scroll;
    }
  }
  @media (min-width: 992px) {
    .bgi-size-lg-auto {
      background-size: auto;
    }
  
    .bgi-size-lg-cover {
      background-size: cover;
    }
  
    .bgi-size-lg-contain {
      background-size: contain;
    }
  
    .bgi-attachment-lg-fixed {
      background-attachment: fixed;
    }
  
    .bgi-attachment-lg-scroll {
      background-attachment: scroll;
    }
  }
  @media (min-width: 1200px) {
    .bgi-size-xl-auto {
      background-size: auto;
    }
  
    .bgi-size-xl-cover {
      background-size: cover;
    }
  
    .bgi-size-xl-contain {
      background-size: contain;
    }
  
    .bgi-attachment-xl-fixed {
      background-attachment: fixed;
    }
  
    .bgi-attachment-xl-scroll {
      background-attachment: scroll;
    }
  }
  @media (min-width: 1400px) {
    .bgi-size-xxl-auto {
      background-size: auto;
    }
  
    .bgi-size-xxl-cover {
      background-size: cover;
    }
  
    .bgi-size-xxl-contain {
      background-size: contain;
    }
  
    .bgi-attachment-xxl-fixed {
      background-attachment: fixed;
    }
  
    .bgi-attachment-xxl-scroll {
      background-attachment: scroll;
    }
  }

  .flotchart {
    width: 100%;
  }

  .text-xs5 {
    font-size: 0.5rem !important;
  }

  .text-xs6 {
    font-size: 0.6rem !important;
  }

  .text-xs7 {
    font-size: 0.7rem !important;
  }

  .text-xs75 {
    font-size: 0.75rem !important;
  }

  .text-xs8 {
    font-size: 0.8rem !important;
  }

  .selected {
    background-color: lightblue !important;
    font-weight: bolder;
  }

  .sticky-top-100 {
    top:100px;
  }

  .display-7 {
    font-size: 1.3rem;
  }

  .badge-min {
    min-width: 68px;
    width: 68px;
  }

  .border-lightgray {
    border-color: lightgray !important;
  }

  .font-mono {
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
    font-size: 12px;
  }

  // .badge-md {
//     font-size: 90% !important;
// }

.callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  background-color: var(--callout-bg, var(--bs-gray-100));
  border-left: 0.25rem solid var(--callout-border, var(--bs-gray-300));
}

.callout-top {
  margin-top: 0.25rem;
}

.callout-warning {
  --callout-bg: rgba(var(--bs-warning-rgb), .075);
  --callout-border: rgba(var(--bs-warning-rgb), .5);
}

.callout-info {
  --callout-bg: rgba(var(--bs-info-rgb), .075);
  --callout-border: rgba(var(--bs-info-rgb), .5);
}

.callout-danger {
  --callout-bg: rgba(var(--bs-danger-rgb), .075);
  --callout-border: rgba(var(--bs-danger-rgb), .5);
}

.callout-primary {
  --callout-bg: rgba(var(--bs-primary-rgb), .075);
  --callout-border: rgba(var(--bs-primary-rgb), .5);
}

.callout-succcess {
  --callout-bg: rgba(var(--bs-success-rgb), .075);
  --callout-border: rgba(var(--bs-success-rgb), .5);
}

@each $name,$color in (default,#777),(primary,#428bca),(success,#5cb85c),(danger,#d9534f),(warning,#f0ad4e),(info,#5bc0de), (bdc,#29527a) {
  .callout-#{$name} {
    border-left-color: $color;
    h4 {
      color: $color;
    }
  }
}

.callout > .callout-title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.425rem;;
}


// quill editor
.editor {
  border: #ebebeb 2px dashed;
  padding: 10px;
  margin-bottom: 10px;
}

.ql-editor {
  height: 200px;
}

/* bottom tabs */

#tabMini {
  padding-left: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1pt solid lightgray;
  background-color: #eee;
  border-top: 2pt solid lightgray;
} 

.minitabs .nav-link {
  color: inherit;
}


#tabMini .nav-item .nav-link {
  padding: 2px 10px;
  margin: 6px;
}
