/* You can add global styles to this file, and also import other style files */

//== Vendor
// @import '~@fortawesome/fontawesome-free/css/brands.css';
// @import '~@fortawesome/fontawesome-free/css/regular.css';
// @import '~@fortawesome/fontawesome-free/css/solid.css';
// @import '~@fortawesome/fontawesome-free/css/fontawesome.css';
// @import '~simple-line-icons/css/simple-line-icons.css';

// @import '~ngx-toastr/toastr.css';
// @import '~spinkit/spinkit.css';
// @import '~loaders.css/loaders.css';

// @import '~sweetalert2/dist/sweetalert2.css';
// @import "~ngx-bootstrap/datepicker/bs-datepicker.css";

//== Bootstrap
//@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";

// @import '~@syncfusion/ej2-angular-base/styles/bootstrap5.scss'; 
// @import '~@syncfusion/ej2-angular-buttons/styles/bootstrap5.scss';  
// @import '~@syncfusion/ej2-angular-navigations/styles/bootstrap5.scss';
// @import '~@syncfusion/ej2-angular-popups/styles/bootstrap5.scss';